<template>
    <div>
      <label v-show="customLabel" class="pb-0 mb-0">{{ customLabel }}</label>
      <div class="position-relative">
        <v-autocomplete
          v-bind="$attrs"
          :items="items"
          :item-text="itemText"
          :item-value="itemValue"
          :value="internalValue"
          @input="updateValue"
          :search-input.sync="internalSearchInput"
          :class="['custom-text-field', cssClasses]"
          :return-object="returnObject"
          outlined
        >
          <template v-slot:item="slotProps">
            <slot name="item" v-bind="slotProps">
              <div>{{ slotProps.item[itemText] }}</div>
            </slot>
          </template>
        </v-autocomplete>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: 'CustomAutoComplete',
    props: {
      value: {
        type: [Object, String, Number, Array],
        default: () => ({})
      },
      searchInput: {
        type: String,
        default: ''
      },
      cssClasses: {
        type: String,
        default: ''
      },
      customLabel: {
        type: String,
        default: null
      },
      itemText: {
        type: String,
        default: 'code'
      },
      itemValue: {
        type: String,
        default: 'id'
      },
      items: {
        type: Array,
        default: () => []
      },
      returnObject: {
      type: Boolean,
      default: false
    }
    },
    computed: {
      internalValue: {
        get () {
          return this.value;
        },
        set (newValue) {
            if (this.returnObject) {
          this.$emit('input', { ...this.value, ...newValue });
            } else {
          this.$emit('input', newValue[this.itemValue]);
        }
        }
      },
      internalSearchInput: {
        get () {
          return this.searchInput;
        },
        set (value) {
          this.$emit('update:searchInput', value);
        }
      }
    },
    methods: {
      updateValue (value) {
        if (this.returnObject) {
          this.$emit('input', { ...this.value, ...value });
            } else {
          this.$emit('input', value[this.itemValue]);
        }
      }
    },
    mounted () {
      console.log('AQUI', this.value);
    }
  };
  </script>
